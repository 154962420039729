
























































































































import { RequestPaging } from '@/utils/util'
import { Component, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import { apiDeviceList, apiDeviceDel, apiDevicePreview } from '@/api/imagetru3d'
@Component({
  components: {
    LsDialog,
  },
})
export default class Device extends Vue {
  $refs!: { plTreeTable: any; addDevicePreview: any }
  pager = new RequestPaging()

  QRCodeImageUrl = ''
  fit = 'fill'

  // 设备列表
  getDeviceList(): void {
    this.pager
      .request({
        callback: apiDeviceList,
        params: { pager_type: 1 },
      })
      .then((res) => {})
  }

  handlePreview(id: number) {
    apiDevicePreview(id).then((res) => {
      this.QRCodeImageUrl = res.img_url
      console.log(this.QRCodeImageUrl)
    })
  }

  handleDelete(id: number) {
    apiDeviceDel(id).then(() => {
      this.getDeviceList()
    })
  }

  created() {
    this.getDeviceList()
  }
}
